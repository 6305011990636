import {
  storefrontOutline,
  fileTrayOutline,
  fileTrayFullOutline,
  ticketOutline,
  listOutline,
  calendarOutline,
  clipboardOutline,
  cubeOutline,
} from 'ionicons/icons';

import APIService from '@s/api.service';

import { Storage } from '@capacitor/storage';
import { Filesystem, Directory } from '@capacitor/filesystem';
import { convertBlobToBase64 } from '@s/helper';

import { Base64 } from 'js-base64';

const state = () => ({
  optionSets: [
    {
      name: 'responseStatuses',
      translationKey: 'optionSets.responseStatuses',
      url: '/response-statuses',
      synchronized: null,
      items: [],
    },
    {
      name: 'ticketStatuses',
      translationKey: 'optionSets.ticketStatuses',
      url: '/ticket-statuses',
      synchronized: null,
      items: [],
    },
    {
      name: 'ticketTypes',
      translationKey: 'optionSets.ticketTypes',
      url: '/ticket-types',
      synchronized: null,
      items: [],
    },
    {
      name: 'actionTypes',
      translationKey: 'optionSets.actionTypes',
      url: '/action-types',
      synchronized: null,
      items: [],
    },
    {
      name: 'locationStatuses',
      translationKey: 'optionSets.locationStatuses',
      url: '/location-statuses',
      synchronized: null,
      items: [],
    },
    {
      name: 'divisions',
      translationKey: 'optionSets.divisions',
      url: '/divisions?filter=light',
      synchronized: null,
      items: [],
    },
    {
      name: 'areas',
      translationKey: 'optionSets.areas',
      url: '/areas?filter=light',
      synchronized: null,
      items: [],
    },
    {
      name: 'failReasons',
      translationKey: 'optionSets.failReasons',
      url: '/fail-reasons?filter=light',
      synchronized: null,
      items: [],
    },
  ],
  sections: [
    {
      name: 'stores',
      translationKey: 'sections.stores',
      icon: storefrontOutline,
      url: '/stores?filter=cache',
      synchronized: null,
      filename: null,
      filesize: null,
      itemCount: 0,
    },
    {
      name: 'projects',
      translationKey: 'sections.projects',
      icon: fileTrayOutline,
      url: '/projects?filter=cache',
      synchronized: null,
      filename: null,
      filesize: null,
      itemCount: 0,
    },
    {
      name: 'surveys',
      translationKey: 'sections.surveys',
      icon: fileTrayFullOutline,
      url: '/surveys?filter=cache',
      synchronized: null,
      filename: null,
      filesize: null,
      itemCount: 0,
    },
    {
      name: 'tickets',
      translationKey: 'sections.tickets',
      icon: ticketOutline,
      url: '/tickets?filter=cache',
      synchronized: null,
      filename: null,
      filesize: null,
      itemCount: 0,
    },
    {
      name: 'scenarios',
      translationKey: 'sections.scenarios',
      icon: listOutline,
      url: '/scenarios?filter=cache',
      synchronized: null,
      filename: null,
      filesize: null,
      itemCount: 0,
    },
    {
      name: 'appointments',
      translationKey: 'sections.appointments',
      icon: calendarOutline,
      url: '/users/:userId/appointments?filter=cache',
      synchronized: null,
      filename: null,
      filesize: null,
      itemCount: 0,
    },
    {
      name: 'procedures',
      translationKey: 'sections.procedures',
      icon: clipboardOutline,
      url: '/procedures?filter=cache',
      synchronized: null,
      filename: null,
      filesize: null,
      itemCount: 0,
    },
    {
      name: 'products',
      translationKey: 'sections.products',
      icon: cubeOutline,
      url: '/products?filter=cache',
      synchronized: null,
      filename: null,
      filesize: null,
      itemCount: 0,
    },
  ],
  savedItems: [],
  networkStatus: null,
  synchronized: null,
});

const getters = {
  getOptionSet: state => name => {
    return state.optionSets.find(optionSet => optionSet.name === name);
  },
  getSection: state => name => {
    return state.sections.find(section => section.name === name);
  },
  getSavedItemCount: state => state.savedItems.length,
  getSavedItems: state => type => {
    if (!type) return state.savedItems;
    return state.savedItems.filter(si => si.type === type);
  },
};

const mutations = {
  SET_NETWORK_STATUS(state, payload) {
    state.networkStatus = payload;
  },
  SET_OPTION_SET(state, { name, data }) {
    const found = state.optionSets.find(optionSet => optionSet.name === name);

    found.items = data.items;
    found.synchronized = data.synchronized;
  },
  SET_SECTION(state, { name, data }) {
    const found = state.sections.find(section => section.name === name);

    found.filename = data.filename;
    found.filesize = data.filesize;
    found.itemCount = data.itemCount;
    found.synchronized = data.synchronized;
  },
  SET_SYNCHRONIZED(state, payload) {
    state.synchronized = payload;
  },
  ADD_SAVED_ITEM(state, payload) {
    state.savedItems.push(payload);
  },
  DELETE_SAVED_ITEM(state, payload) {
    state.savedItems.splice(payload, 1);
  },
  SET_SAVED_ITEMS(state, payload) {
    state.savedItems = payload;
  },
};

const actions = {
  // Trigger on network status change (online, offline)
  async networkStatusChange({ commit }, networkStatus) {
    commit('SET_NETWORK_STATUS', networkStatus);
  },
  // Download online data to device (files and local storage)
  async synchronize({ state, commit, rootState }) {
    const userId = rootState.auth.authData.userId;

    // Option Sets
    for (let optionSet of state.optionSets) {
      const { data } = await APIService.get(optionSet.url);

      commit('SET_OPTION_SET', {
        name: optionSet.name,
        data: {
          items: data,
          synchronized: new Date(),
        },
      });
    }

    await Storage.set({
      key: 'optionSets',
      value: JSON.stringify(
        state.optionSets.map(optionSet => {
          return {
            name: optionSet.name,
            items: optionSet.items,
            synchronized: optionSet.synchronized,
          };
        })
      ),
    });

    // Sections
    for (let section of state.sections) {
      const { data, headers } = await APIService.get(
        section.url.replace(':userId', userId)
      );

      const b64data = Base64.encode(JSON.stringify(data));

      try {
        await Filesystem.writeFile({
          path: `${section.name}.json`,
          data: b64data,
          directory: Directory.Data,
        });

        commit('SET_SECTION', {
          name: section.name,
          data: {
            filename: `${section.name}.json`,
            filesize: headers['content-length'],
            itemCount: data.length,
            synchronized: new Date(),
          },
        });
      } catch (error) {
        throw new Error(error);
      }
    }

    await Storage.set({
      key: 'sections',
      value: JSON.stringify(
        state.sections.map(section => {
          return {
            name: section.name,
            filename: section.filename,
            filesize: section.filesize,
            itemCount: section.itemCount,
            synchronized: section.synchronized,
          };
        })
      ),
    });

    // Synchronized
    commit('SET_SYNCHRONIZED', new Date());

    await Storage.set({
      key: 'synchronized',
      value: state.synchronized,
    });
  },
  // Load local saved data to app (state)
  async loadState({ commit }) {
    // Option Sets
    const optionSets = await Storage.get({
      key: 'optionSets',
    });

    if (optionSets.value) {
      for (let optionSet of JSON.parse(optionSets.value)) {
        const { name, ...data } = optionSet;
        commit('SET_OPTION_SET', {
          name,
          data,
        });
      }
    }

    // Sections
    const sections = await Storage.get({
      key: 'sections',
    });

    if (sections.value) {
      for (let section of JSON.parse(sections.value)) {
        const { name, ...data } = section;
        commit('SET_SECTION', {
          name,
          data,
        });
      }
    }

    // Synchronized
    const synchronized = await Storage.get({
      key: 'synchronized',
    });

    if (synchronized.value) {
      commit('SET_SYNCHRONIZED', new Date(synchronized.value));
    }

    // Saved items
    const savedItems = await Storage.get({
      key: 'savedItems',
    });

    if (savedItems.value) {
      commit('SET_SAVED_ITEMS', JSON.parse(savedItems.value));
    }
  },
  // Clear state and delete data from device
  async clearState({ state, commit }) {
    // Option Sets
    for (let optionSet of state.optionSets) {
      commit('SET_OPTION_SET', {
        name: optionSet.name,
        data: {
          items: 0,
          synchronized: null,
        },
      });
    }

    await Storage.remove({
      key: 'optionSets',
    });

    for (let section of state.sections) {
      try {
        await Filesystem.deleteFile({
          path: `${section.name}.json`,
          directory: Directory.Data,
        });

        commit('SET_SECTION', {
          name: section.name,
          data: {
            filename: null,
            filesize: null,
            itemCount: 0,
            synchronized: null,
          },
        });
      } catch (error) {
        console.log(error);
      }
    }

    await Storage.remove({
      key: 'sections',
    });

    commit('SET_SYNCHRONIZED', null);

    await Storage.remove({
      key: 'synchronized',
    });
  },
  // add offline response to saved state
  async saveOfflineResponse({ state, commit }, payload) {
    // Save/write files to device
    for (let upload of payload.uploads) {
      const ext = upload.file.name.split('.').pop();
      const filepath = `responses/${new Date().getTime()}.${ext}`;
      await Filesystem.writeFile({
        path: filepath,
        data: await convertBlobToBase64(upload.file),
        directory: Directory.Data,
        recursive: true,
      });
      upload.file = filepath;
    }

    // Save payload to storage
    commit('ADD_SAVED_ITEM', {
      type: 'newResponse',
      data: payload,
      created: new Date(),
    });

    await Storage.set({
      key: 'savedItems',
      value: JSON.stringify(state.savedItems),
    });
  },
  // add offline response to saved state
  async saveOfflineTicket({ state, commit }, payload) {
    // Save upload file
    if (payload.upload) {
      const ext = payload.upload.file.name.split('.').pop();
      const filepath = `tickets/${new Date().getTime()}.${ext}`;
      await Filesystem.writeFile({
        path: filepath,
        data: await convertBlobToBase64(payload.upload.file),
        directory: Directory.Data,
        recursive: true,
      });
      payload.upload.file = filepath;
    }

    // Save payload to storage
    commit('ADD_SAVED_ITEM', {
      type: 'newTicket',
      data: payload,
      created: new Date(),
    });

    await Storage.set({
      key: 'savedItems',
      value: JSON.stringify(state.savedItems),
    });
  },
  // add offline response to saved state
  async saveOfflineTicketAction({ state, commit }, payload) {
    // Save upload file
    if (payload.upload) {
      const ext = payload.upload.file.name.split('.').pop();
      const filepath = `tickets/${new Date().getTime()}.${ext}`;
      await Filesystem.writeFile({
        path: filepath,
        data: await convertBlobToBase64(payload.upload.file),
        directory: Directory.Data,
        recursive: true,
      });
      payload.upload.file = filepath;
    }

    // Save payload to storage
    commit('ADD_SAVED_ITEM', {
      type: 'newTicketAction',
      data: payload,
      created: new Date(),
    });

    await Storage.set({
      key: 'savedItems',
      value: JSON.stringify(state.savedItems),
    });
  },
  // Delete offline saved item
  async deleteOfflineSavedItem({ state, commit }, index) {
    if (state.savedItems[index].data.upload) {
      try {
        await Filesystem.deleteFile({
          path: state.savedItems[index].data.upload.file,
          directory: Directory.Data,
        });
      } catch (error) {
        console.log(error);
      }
    }
    if (state.savedItems[index].data.uploads) {
      for (let upload of state.savedItems[index].data.uploads) {
        try {
          await Filesystem.deleteFile({
            path: upload.file,
            directory: Directory.Data,
          });
        } catch (error) {
          console.log(error);
        }
      }
    }

    commit('DELETE_SAVED_ITEM', index);

    await Storage.set({
      key: 'savedItems',
      value: JSON.stringify(state.savedItems),
    });
  },
  // Delete all offline saved items
  async deleteAllOfflineSavedItems({ state, commit }) {
    for (let savedItem of state.savedItems) {
      if (savedItem.data.upload) {
        try {
          await Filesystem.deleteFile({
            path: savedItem.data.upload.file,
            directory: Directory.Data,
          });
        } catch (error) {
          console.log(error);
        }
      }
      if (savedItem.data.uploads) {
        for (let upload of savedItem.data.uploads) {
          try {
            await Filesystem.deleteFile({
              path: upload.file,
              directory: Directory.Data,
            });
          } catch (error) {
            console.log(error);
          }
        }
      }
    }

    commit('SET_SAVED_ITEMS', []);

    await Storage.set({
      key: 'savedItems',
      value: JSON.stringify(state.savedItems),
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
