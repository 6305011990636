import { Storage } from '@capacitor/storage';

const state = () => ({
  selectedSurvey: null,
});

const mutations = {
  SELECT_SURVEY(state, payload) {
    state.selectedSurvey = payload;
  },
  DESELECT_SURVEY(state) {
    state.selectedSurvey = null;
  },
};

const actions = {
  async selectSurvey({ commit }, payload) {
    commit('SELECT_SURVEY', payload);

    await Storage.set({
      key: 'selectedSurvey',
      value: JSON.stringify(payload),
    });
  },
  async deselectSurvey({ commit }) {
    commit('DESELECT_SURVEY');

    await Storage.remove({
      key: 'selectedSurvey',
    });
  },
  async init({ commit }) {
    const selectedSurvey = await Storage.get({
      key: 'selectedSurvey',
    });

    if (selectedSurvey.value) {
      commit('SELECT_SURVEY', JSON.parse(selectedSurvey.value));
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
