<template>
  <ion-page>
    <ion-tabs>
      <ion-router-outlet></ion-router-outlet>
      <ion-tab-bar slot="bottom">
        <ion-tab-button tab="stores" href="/tabs/stores">
          <ion-icon :icon="icons.storefrontOutline"></ion-icon>
        </ion-tab-button>

        <ion-tab-button
          v-if="hasFeatProjects"
          tab="projects"
          href="/tabs/projects"
        >
          <ion-icon :icon="icons.fileTrayOutline"></ion-icon>
        </ion-tab-button>

        <ion-tab-button tab="appointments" href="/tabs/appointments">
          <ion-icon :icon="icons.calendarOutline"></ion-icon>
        </ion-tab-button>

        <ion-tab-button tab="procedures" href="/tabs/help">
          <ion-icon :icon="icons.helpBuoyOutline"></ion-icon>
        </ion-tab-button>

        <ion-tab-button tab="profile" href="/tabs/profile">
          <ion-icon :icon="icons.personOutline"></ion-icon>
          <ion-badge v-if="notificationCount > 0" color="danger">
            {{ notificationCount }}
          </ion-badge>
        </ion-tab-button>
      </ion-tab-bar>
    </ion-tabs>
  </ion-page>
</template>

<script>
import { mapState, mapActions } from 'vuex';

import {
  IonRouterOutlet,
  IonIcon,
  IonPage,
  IonTabBar,
  IonTabButton,
  IonTabs,
  IonBadge,
} from '@ionic/vue';
import {
  storefrontOutline,
  fileTrayOutline,
  calendarOutline,
  helpBuoyOutline,
  personOutline,
} from 'ionicons/icons';

export default {
  components: {
    IonRouterOutlet,
    IonIcon,
    IonPage,
    IonTabBar,
    IonTabButton,
    IonTabs,
    IonBadge,
  },
  data() {
    return {
      refreshInterval: 60000,
      refreshHandler: null,
      icons: {
        storefrontOutline,
        fileTrayOutline,
        calendarOutline,
        helpBuoyOutline,
        personOutline,
      },
    };
  },
  computed: {
    ...mapState('auth', ['loggedIn', 'authData']),
    ...mapState('notifications', ['notificationCount']),
    hasFeatProjects() {
      return (
        process.env.VUE_APP_FEAT_PROJECTS &&
        process.env.VUE_APP_FEAT_PROJECTS == 'true'
      );
    },
  },
  ionViewDidEnter() {
    this.fetchNotifications();

    this.refreshHandler = setInterval(
      this.fetchNotifications,
      this.refreshInterval
    );
  },
  ionViewDidLeave() {
    clearInterval(this.refreshHandler);
  },
  methods: {
    ...mapActions('notifications', ['fetchNotifications']),
  },
};
</script>
