<template>
  <ion-page>
    <ion-content :fullscreen="true">
      <div class="odn-menu">
        <div
          v-for="(item, index) in filteredItems"
          :key="index"
          class="odn-menu-item"
          @click="goTo(item.link)"
        >
          <div class="odn-menu-item-icon">
            <img
              :src="`${s3Url}/medias/images/icons/${item.icon}`"
              alt="Icon"
            />
          </div>
          <div class="odn-menu-item-label">
            {{ item.label }}
          </div>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { Storage } from '@capacitor/storage';

import { IonContent, IonPage, modalController } from '@ionic/vue';

import ODNModalPrivacy from '@m/odn-modal-privacy.vue';

export default {
  name: 'Menu',
  components: {
    IonContent,
    IonPage,
  },
  data() {
    return {
      items: [
        {
          label: this.$t('menu.stores'),
          link: '/tabs/stores',
          icon: 'icon-stores.svg',
          enabled: true,
        },
        {
          label: this.$t('menu.projects'),
          link: '/tabs/projects',
          icon: 'icon-projects.svg',
          enabled: this.hasFeatProjects(),
        },
        {
          label: this.$t('menu.appointments'),
          link: '/tabs/appointments',
          icon: 'icon-appointments.svg',
          enabled: true,
        },
        {
          label: this.$t('menu.procedures'),
          link: '/tabs/help/procedures',
          icon: 'icon-procedures.svg',
          enabled: true,
        },
        {
          label: this.$t('menu.emergencyNumbers'),
          link: '/tabs/help/emergency-numbers',
          icon: 'icon-emergency-numbers.svg',
          enabled: true,
        },
        {
          label: this.$t('menu.profile'),
          link: '/tabs/profile',
          icon: 'icon-profile.svg',
          enabled: true,
        },
      ],
    };
  },
  computed: {
    filteredItems() {
      return this.items.filter((item) => item.enabled);
    },
    s3Url() {
      return process.env.VUE_APP_S3_URL;
    },
  },
  async ionViewDidEnter() {
    const privacyRead = await Storage.get({ key: 'privacyRead' });
    if (!privacyRead.value) {
      const modal = await modalController.create({
        component: ODNModalPrivacy,
        backdropDismiss: false,
        keyboardClose: false,
      });
      await modal.present();

      const result = (await modal.onDidDismiss()).data;

      if (result) {
        await Storage.set({ key: 'privacyRead', value: 'true' });
      }
    }
  },
  methods: {
    goTo(link) {
      return this.$router.push(link);
    },
    hasFeatProjects() {
      return (
        process.env.VUE_APP_FEAT_PROJECTS &&
        process.env.VUE_APP_FEAT_PROJECTS == 'true'
      );
    },
  },
};
</script>

<style lang="scss">
.odn-menu {
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  &-item {
    flex: 1 1 0px;
    padding: 0 2rem;

    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;

    &:nth-child(odd) {
      background-color: rgba(#2875c2, 0.1);
    }

    &-icon {
      width: 64px;
      height: auto;
    }

    &-label {
      padding-left: 2rem;
      font-size: 1.2rem;
      opacity: 0.8;
    }
  }
}
</style>
