import { Storage } from '@capacitor/storage';

const state = () => ({
  selectedResponse: null,
});

const mutations = {
  SELECT_RESPONSE(state, payload) {
    state.selectedResponse = payload;
  },
  DESELECT_RESPONSE(state) {
    state.selectedResponse = null;
  },
};

const actions = {
  async selectResponse({ commit }, payload) {
    commit('SELECT_RESPONSE', payload);

    await Storage.set({
      key: 'selectedResponse',
      value: JSON.stringify(payload),
    });
  },
  async deselectResponse({ commit }) {
    commit('DESELECT_RESPONSE');

    await Storage.remove({
      key: 'selectedResponse',
    });
  },
  async init({ commit }) {
    const selectedResponse = await Storage.get({
      key: 'selectedResponse',
    });

    if (selectedResponse.value) {
      commit('SELECT_RESPONSE', JSON.parse(selectedResponse.value));
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
