<template>
  <ion-page>
    <ion-header :translucent="true">
      <ion-toolbar>
        <ion-buttons slot="end">
          <ion-button @click="dismiss">
            <ion-icon slot="icon-only" :icon="close"></ion-icon>
          </ion-button>
        </ion-buttons>
        <ion-title>
          {{ $t('modals.languages') }}
        </ion-title>
      </ion-toolbar>
    </ion-header>

    <ion-content :fullscreen="true">
      <div class="odn-modal-languages">
        <div
          class="odn-modal-languages-item ion-activatable"
          v-for="locale in $i18n.availableLocales"
          :key="locale"
          :class="{ active: locale === $i18n.locale }"
          @click="selectLocale(locale)"
        >
          <span>{{ localeLabels[locale] }}</span>
          <ion-ripple-effect></ion-ripple-effect>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { mapActions } from 'vuex';

import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonButton,
  IonButtons,
  IonIcon,
  IonRippleEffect,
  modalController,
} from '@ionic/vue';
import { close } from 'ionicons/icons';

export default {
  name: 'ModalLanguages',
  components: {
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    IonButton,
    IonButtons,
    IonIcon,
    IonRippleEffect,
  },
  data() {
    return {
      close,
      localeLabels: {
        de: 'Deutsch',
        en: 'English',
        es: 'Español',
        fr: 'Français',
        ja: '日本語',
        ko: '한국어',
        nl: 'Nederlands',
        pl: 'Język polski',
        pt: 'Português',
        ru: 'русский язык',
      },
    };
  },
  methods: {
    ...mapActions('prefs', ['setLocale']),
    dismiss() {
      modalController.dismiss();
    },
    selectLocale(locale) {
      this.$i18n.locale = locale;
      this.setLocale(locale);
      this.dismiss();
    },
  },
};
</script>

<style lang="scss">
.odn-modal {
  &-languages {
    position: relative;
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    justify-content: stretch;

    &-item {
      position: relative;
      flex: auto;
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 1rem 2rem;
      cursor: pointer;

      &.active {
        color: var(--ion-color-primary);
        background-color: rgba(var(--ion-color-primary-rgb), 0.1);
      }
    }
  }
}
</style>
